@use 'scss/mixins' as mixins;
.footer_container {
  display: flex;
  padding: 4px 48px;
  justify-content: space-between;
  align-items: center;
  gap: 48px;
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.52);
  color: rgba(255, 255, 255, 0.8);
  @include mixins.respond-to('medium') {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .left {
    display: flex;
    gap: 24px;
    a {
      color: rgba(255, 255, 255, 0.8);
      font-family: Outfit;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.42857;
    }
  }
  .center {
    display: flex;
    gap: 24px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    .social {
      display: flex;
      align-items: center;
      gap: 8px;
      color: rgba(255, 255, 255, 0.8);
      font-family: Outfit;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      &:hover span {
        text-decoration: underline;
      }
    }
  }
  .right {
    text-align: right;
    font-family: Outfit;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.42;
  }
}
