// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/variables';

.rotate-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top: 2px solid variables.$secondary-text-white;
  border-left: 2px solid variables.$secondary-text-white;
  animation: rotate 1s infinite linear;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
