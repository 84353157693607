// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

$default-font-family: 'Poppins';

// Theme Colors
// Primary Color
$primary: #4c33ff;
$primary-container: #f4f0ff;

// Secondary Color
$secondary: #d9d9d9;
$secondary-container: #d9d9d9;

// Text Color
$primary-text: rgba(0, 0, 0, 0.87);
$primary-text-black: #15151f;
$secondary-text: rgba(0, 0, 0, 0.6);
$disabled-text: rgba(0, 0, 0, 0.38);
$primary-text-white: rgba(255, 255, 255, 0.87);
$primary-text-black: #15151f;
$secondary-text-white: #e8e8eb;
$disabled-text-white: rgba(255, 255, 255, 0.38);
$white-text: #fff;
$editor-qualifier-input: #e6ff50;
$home-qualifier-input: #21bf7a;

// State Color
$success: #1cda77;
$success-container: #f4fdf8;
$warning: #fab53e;
$caution: #feb625;
$caution-container: #fffbf4;
$error: #f54f4a;
$error-dark: #cf3434;
$error-container: #fbe8ed;
$disable: #9e9e9e;
$disable-container: #f2f2f2;
$placeholder: #f4f4f5;
$link: #745cff;

// Functional Color
$outline: rgba(0, 0, 0, 0.38);
$divider: rgba(0, 0, 0, 0.12);
$divider-light: rgba(0, 0, 0, 0.06);
$overlay: rgba(0, 0, 0, 0.6);
$primary-cover1: rgba(102, 72, 252, 0.08);
$primary-cover2: rgba(102, 72, 252, 0.12);
$primary-cover3: rgba(102, 72, 252, 0.16);
$white-cover1: rgba(255, 255, 255, 0.08);
$white-cover2: rgba(255, 255, 255, 0.12);
$white-cover3: rgba(255, 255, 255, 0.16);
$white-cover4: rgba(255, 255, 255, 0.24);
$loading-background: rgba(255, 255, 255, 0.3);
$black-cover1: rgba(0, 0, 0, 0.08);
$black-cover2: rgba(0, 0, 0, 0.12);
$black-cover3: rgba(0, 0, 0, 0.16);
$error-cover1: rgba(212, 21, 73, 0.05);
$error-cover2: rgba(212, 21, 73, 0.08);
$current-selected: #0daff4;
$qualifier-selected: #f4f0ff;

// Surface Color
$surface: #fbfbfc;
$surface1: #f4f2fc;
$surface2: #efedfc;
$surface3: #e9e6fc;
$surface4: #e6e2fc;
$surface-neutral-1: #f2f2f2;
$surface-neutral-2: #e8e8eb;
$login-container: #dedede;

// Neutral
$neutral: #e2e3e4;
$neutralBlack: #000;
$white: #fff;
$grey10: #fbfbfc;
$grey20: #f4f4f5;
$grey30: #e2e3e4;
$grey60: #797f85;
$grey70: #545b63;
$grey80: #39414b;

$background: #f9f9fa;
$glass: rgba(128, 128, 128, 0.3);

$glass-panel-default: rgba(255, 255, 255, 0.12);
$glass-panel-hover: rgba(255, 255, 255, 0.24);
$glass-panel-active: rgba(0, 0, 0, 0.24);

// Global Font Styles
$font-sizes: (
  1: 12px,
  2: 14px,
  3: 16px,
  4: 20px,
);

$headline-text-styles: (
  'l': (
    'fs': 32px,
    'lh': 40px,
    'fw': 600,
  ),
  'm': (
    'fs': 28px,
    'lh': 36px,
    'fw': 500,
  ),
  's': (
    'fs': 24px,
    'lh': 32px,
    'fw': 400,
  ),
);

$title-text-styles: (
  'l': (
    'fs': 20px,
    'lh': 28px,
    'fw': 600,
  ),
  'm': (
    'fs': 16px,
    'lh': 24px,
    'fw': 600,
  ),
  's': (
    'fs': 14px,
    'lh': 24px,
    'fw': 600,
  ),
);

$body-text-styles: (
  'l': (
    'fs': 16px,
    'lh': 24px,
    'fw': 400,
  ),
  'm': (
    'fs': 14px,
    'lh': 20px,
    'fw': 400,
  ),
  's': (
    'fs': 12px,
    'lh': 16px,
    'fw': 400,
  ),
);

$label-text-styles: (
  'l': (
    'fs': 16px,
    'lh': 24px,
    'fw': 500,
  ),
  'm': (
    'fs': 14px,
    'lh': 20px,
    'fw': 500,
  ),
  's': (
    'fs': 12px,
    'lh': 16px,
    'fw': 500,
  ),
);

// Global Box Shadows
$shadow-color: #1e2732;

$shadow-x-scope-1: 0px 0px 1px;
$shadow-y-scope-1: 0px 1px 2px;

$shadow-x-scope-2: 0px 1px 2px;
$shadow-y-scope-2: 0px 3px 6px;

$shadow-x-scope-3: 0px 1.5px 3px;
$shadow-y-scope-3: 0px 4px 8px;

$shadow-x-scope-4: 0px 2px 4px;
$shadow-y-scope-4: 0px 4px 12px;

$shadow-x-scope-5: 0px 3px 4px;
$shadow-y-scope-5: 0px 8px 16px;

$shadow-x-scope-6: 0px 4px 5px;
$shadow-y-scope-6: 0px 12px 20px;

$box-shadow: (
  '1': (
    $shadow-x-scope-1 rgba($shadow-color, 0.2),
    $shadow-y-scope-1 rgba($shadow-color, 0.1),
  ),
  '2': (
    $shadow-x-scope-2 rgba($shadow-color, 0.2),
    $shadow-y-scope-2 1px rgba($shadow-color, 0.1),
  ),
  '3': (
    $shadow-x-scope-3 rgba($shadow-color, 0.15),
    $shadow-y-scope-3 2px rgba($shadow-color, 0.1),
  ),
  '4': (
    $shadow-x-scope-4 rgba($shadow-color, 0.2),
    $shadow-y-scope-4 rgba($shadow-color, 0.1),
  ),
  '5': (
    $shadow-x-scope-5 rgba(30, 39, 50, 0.15),
    $shadow-y-scope-5 rgba(30, 39, 50, 0.1),
  ),
  '6': (
    $shadow-x-scope-6 rgba(30, 39, 50, 0.15),
    $shadow-y-scope-6 6px rgba(30, 39, 50, 0.1),
  ),
);

$text-shadow: (
  '1': (
    $shadow-x-scope-1 rgba($shadow-color, 0.2),
    $shadow-y-scope-1 rgba($shadow-color, 0.1),
  ),
  '2': (
    $shadow-x-scope-2 rgba($shadow-color, 0.2),
    $shadow-y-scope-2 rgba($shadow-color, 0.1),
  ),
  '3': (
    $shadow-x-scope-3 rgba($shadow-color, 0.15),
    $shadow-y-scope-3 rgba($shadow-color, 0.1),
  ),
  '4': (
    $shadow-x-scope-4 rgba($shadow-color, 0.2),
    $shadow-y-scope-4 rgba($shadow-color, 0.1),
  ),
  '5': (
    $shadow-x-scope-5 rgba(30, 39, 50, 0.15),
    $shadow-y-scope-5 rgba(30, 39, 50, 0.1),
  ),
  '6': (
    $shadow-x-scope-6 rgba(30, 39, 50, 0.15),
    $shadow-y-scope-6 rgba(30, 39, 50, 0.1),
  ),
);

// Global Border Radius
$border-radius-2: 2px;
$border-radius-3: 3px;
$border-radius-4: 4px;
$border-radius-6: 6px;
$border-radius-8: 8px;
$border-radius-12: 12px;
$border-radius-24: 24px;
$border-radius-30: 30px;

// Global Z-index
$z-index-preview-mask: 2;
$z-index-floatPanel: 3;
$z-index-floatIcon: 5;
$z-index-menu: 10;

$z-index-mask: 800;
$z-index-mask-panel: 810;
$z-index-dialog: 998;
$z-index-dialog-panel: 999;

$z-index-notification: 1000;

$breakpoints: (
  'medium': '1086px',
);
