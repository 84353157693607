// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/variables';

.container {
  padding: 6px 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 44px;
  width: auto;
  pointer-events: none;
  border-radius: variables.$border-radius-4;
  background: variables.$overlay;
  z-index: variables.$z-index-dialog-panel;
  display: flex;
  align-items: center;
  backdrop-filter: blur(50px);

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;

    &.success {
      fill: variables.$success;
    }

    &.warning {
      fill: variables.$warning;
    }
  }

  .content {
    color: variables.$white;
    white-space: nowrap;
  }
}
