// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/variables';
@use 'scss/mixins' as mixins;

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  z-index: variables.$z-index-floatPanel;

  .logo {
    width: 220px;
    height: 64px;
  }

  .logo_mini {
    width: 44px;
    height: 44px;
    display: none;
  }

  @include mixins.respond-to('medium') {
    .logo {
      display: none;
    }
    .logo_mini {
      display: block;
    }
  }

  .user_info {
    display: flex;
    align-items: center;
    position: relative;
    .discord_icon {
      width: 24px;
      height: 24px;
      fill: #fff;
      margin-right: 4px;
    }
    .discord_button {
      margin-right: 24px;
      .shot {
        display: none;
      }
      @include mixins.respond-to('medium') {
        .shot {
          display: block;
        }
        .long {
          display: none;
        }
      }
    }
    .info {
      display: flex;
      align-items: center;
      .plan_info {
        color: rgba(255, 255, 255, 0.6);
        text-align: right;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.12;
        margin-right: 12px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .count {
          color: var(--Text-Color-Secondary-Text-White, #e8e8eb);
          font-weight: 700;
        }

        .level {
          line-height: 1.12;
        }
      }
      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
      }
      @include mixins.respond-to('medium') {
        .plan_info {
          display: none;
        }
      }
    }
  }
}
