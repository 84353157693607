// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/variables';

.popover-mask {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: variables.$z-index-mask;
  background: rgba(0, 0, 0, 0.6);
  pointer-events: fill;
  cursor: default;

  &.dark {
    background: rgba(0, 0, 0, 0.36);
  }

  &.white {
    background: rgba(255, 255, 255, 0.3);
  }

  &.transparent {
    background: transparent;
  }

  &.black {
    background: #000;
  }
}
