@use 'scss/mixins' as mixins;
.pageContainer {
  background: linear-gradient(180deg, #7651c5 0%, #fff 100%, #fff 100.01%);
  height: 100%;
  overflow-y: auto;
  :global {
    .header {
      margin-top: 18px;
    }
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 24px 64px 24px;
  gap: 36px;
  flex-grow: 1;
}

.containerTitle {
  color: #fff;
  font-size: 64px;
  font-weight: 600;
  padding-top: 38px;
  @include mixins.respond-to('medium') {
    font-size: 36px;
  }
}
.toggleSwitch {
  flex-shrink: 0;
}
.planOptionWrap {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 0;
  align-self: stretch;
  .planItem {
    display: flex;
    width: 330px;
    padding: 36px 34px;
    flex-direction: column;
    gap: 36px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.8);
    /* Shadow 1 */
    box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
      0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
    border: 2px solid transparent;
    box-sizing: padding-box;
    min-width: 330px;
    max-width: 504px;
    flex: 1 1 330px;
    .top {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .title {
        color: var(--Text-Color-Primary-Text-Black, #15151f);
        font-size: 20px;
        font-weight: 600;
        line-height: 1.4;
        display: flex;
        justify-content: space-between;
        .flag {
          display: flex;
          height: 28px;
          padding: 6px 12px;
          justify-content: center;
          align-items: center;
          border-radius: 2px;
          background: #43aa13;

          color: var(--Primary-Primary-Container, #f4f0ff);
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.75;
        }
      }

      .price {
        height: 50px;
        .priceText {
          display: flex;
          align-items: center;
          gap: 8px;
          .perMonthCharge {
            color: #333;
            font-size: 46px;
            font-weight: 600;
            line-height: 0.87;
            font-family: Outfit;
          }
          .append {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--Text-Color-Secondary-Text-Black, #56565c);
            text-align: center;

            font-size: 14px;
            font-weight: 500;
            line-height: 2;
            span:last-child {
              margin-top: -6px;
            }
            .sourcePrice {
              font-size: 21px;
              color: #aaa;
              font-family: Outfit;
              font-weight: 600;
              line-height: 1;
              text-decoration: line-through;
            }
          }
        }
      }

      .creditsInfo {
        .creditsPerMonth {
          color: #3021d9;
          font-size: 14px;
          font-weight: 500;
          line-height: 2;
        }
        .tip {
          color: var(--Text-Color-Secondary-Text-Black, #56565c);
          font-size: 12px;
          font-weight: 400;
          line-height: 2.33;
          margin-top: -8px;
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
    .bottomTips {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      .tipItem {
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        line-height: 2;
        height: 28px;
        .icon {
          fill: #21bf7a;
        }
        .textWrap {
          display: flex;
          align-items: center;
        }
        .text {
          color: var(--Text-Color-Primary-Text-Black, #15151f);
          font-size: 14px;
          font-weight: 500;
        }
        .strongText {
          color: var(--Text-Color-Primary-Text-Black, #15151f);
          font-size: 14px;
          font-weight: 700;
          margin: 0 3px;
          white-space: nowrap;
        }
        .thinText {
          color: var(--Text-Color-Secondary-Text-Black, #56565c);
          font-size: 12px;
          font-weight: 400;
        }
        .colorText {
          color: #3021d9;
          font-size: 14px;
          font-weight: 500;
          margin-right: 3px;
        }
        .tipIcon {
          fill: #9e9e9e;
          margin-left: 8px;
        }
      }
    }
    &:hover {
      background: rgba(255, 255, 255, 1);
      /* Shadow 3 */
      box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
        0px 6px 12px 0px rgba(3, 4, 71, 0.1);
    }
    &.active {
      background: linear-gradient(
        to bottom,
        #b133ff 0%,
        #4232ff 30%,
        #55008a 100%
      );
      box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
        0px 6px 12px 0px rgba(3, 4, 71, 0.1);
      position: relative;
      border: none;

      > * {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        position: absolute;
        left: 5px;
        top: 5px;
        background: #fff;
        border-radius: 11px;
        z-index: 0;
      }
    }
  }
  @include mixins.respond-to('medium') {
    flex-direction: column;
    align-items: center;
    .planItem {
      width: 100%;
      margin: 0 auto;
    }
  }
}
.bootomSectionWrap {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 0;
  align-self: stretch;
  .section {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid var(--neutral-color-divider-12, rgba(0, 0, 0, 0.12));
    background: rgba(255, 255, 255, 0.8);
    width: 507px;
    flex: 1 1 507px;
    max-width: 768px;
    .title {
      display: flex;
      justify-content: space-between;
      height: 32px;
      padding: 6px 24px;
      align-items: center;
      gap: 6px;
      align-self: stretch;
      color: var(--Text-Color-Secondary-Text-Black, #56565c);
      font-weight: 500;
      line-height: 2;
      font-size: 18px;
      .right {
        color: #3021d9;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
      }
    }
    &.billing {
      gap: 8px;
      .info {
        display: flex;
        height: 32px;
        padding: 6px 24px;
        align-items: center;
        gap: 6px;
        align-self: stretch;

        color: var(--Text-Color-Secondary-Text-Black, #56565c);

        font-size: 14px;
        font-weight: 500;
        line-height: 2;
        justify-content: space-between;
        .right {
          display: flex;
          align-items: center;
          color: var(--Primary-Primary-Dark, #030447);
          svg {
            fill: var(--Primary-Primary-Dark, #030447);
          }
          .credit {
            margin-left: 4px;
            color: var(--Primary-Primary-Dark, #030447);
          }
        }
      }

      .tip {
        display: flex;
        padding: 6px 24px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        color: #43aa13;

        font-size: 14px;
        font-weight: 500;
        line-height: 2;
      }
    }
    &.credit {
      gap: 4px;
      .info {
        display: flex;
        padding: 6px 24px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        color: var(--Text-Color-Secondary-Text-Black, #56565c);
        font-size: 14px;
        font-weight: 500;
      }
      .creditOption {
        display: flex;
        gap: 24px;
        justify-content: space-around;
        padding: 0px 24px;
        .creditOptionItem {
          display: flex;
          padding: 24px 24px 18px 24px;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          font-family: Outfit;
          border-radius: 8px;
          background: #f1f4f9;
          cursor: pointer;
          width: 137px;
          user-select: none;
          border: 2px solid transparent;
          .creditOptionItemIcon {
            height: 24px;
            width: 24px;
            fill: #1f1f1f;
          }
          span:first-child {
            color: var(--Primary-Primary-Dark, #030447);
            font-size: 24px;
            font-weight: 400;
            line-height: 0.875;
            display: flex;
            align-items: center;
          }
          span:second-child {
            color: var(--Text-Color-Secondary-Text-Black, #56565c);
            font-size: 18px;
            font-weight: 600;
          }
          &:hover {
            box-shadow: 0px 0.2px 1px 0px rgba(3, 4, 71, 0.08),
              0px 1.5px 3px 0px rgba(3, 4, 71, 0.1);
          }
          &.disabled {
            color: var(--Support-Color-Disable, #aaaaad);
            cursor: not-allowed;
            .creditOptionItemIcon {
              fill: #aaaaad;
            }
            span:first-child {
              color: var(--Support-Color-Disable, #aaaaad);
            }
          }
          &.disabled:hover {
            box-shadow: unset;
          }
          &.active {
            border-color: #3021d9;
          }
        }
      }
      .creditSectionFooter {
        display: flex;
        padding: 0px 24px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        margin-top: 8px;
      }
    }
  }
  @include mixins.respond-to('medium') {
    flex-direction: column;
    align-items: center;
    .section {
      max-width: 507px;
      margin: 0 auto;
      flex-basis: auto;
      width: 100%;
    }
  }
}
.tipContent {
  display: flex;
  padding: 16px 6px;
  flex-direction: column;
  gap: 6px;
  border-radius: 8px;
  background: #f1f4f9;
  .tipItem {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--Text-Color-Primary-Text-Black, #15151f);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.428;
    position: relative;
    .dotIcon {
      padding: 0 10px;
    }
  }
}

.downgradeToast {
  width: 396px;

  button {
    max-width: 203px;
    white-space: nowrap;
  }
}
