// Copyright 2023 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
@use 'sass:map';
@use 'scss/variables.scss' as var;
$primary-color: #3021d9;
$btnTheme: (
  colors: (
    primary: #3021d9,
    default: #1f1f1f,
    large: #f7f3f2,
    middle: #f2f2f2,
  ),
);

//用于处理单行或多行文本溢出时显示省略号。
@mixin text-overflow-ellipsis($lines: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @if $lines > 1 {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}
@mixin project-grid(
  $gridItemWidth: 240px,
  $column-gap: 16px,
  $justify-items: flex-start,
  $repeat: auto-fill,
  $row-gap: 24px,
  $justify-content: space-between
) {
  padding: 0 60px 0 40px;
  display: grid;
  grid-template-columns: repeat($repeat, minmax($gridItemWidth, 1fr));
  row-gap: $row-gap;
  column-gap: $column-gap;
  justify-content: $justify-content;
  justify-items: $justify-items;
}
@mixin mixin-backgroundColor-withStatus(
  $color,
  $hover-color: lighten($color, 30%),
  $disable-color: #ababab
) {
  background-color: $color;
  &:hover {
    background-color: $hover-color;
  }
  &:active {
    background-color: $color;
  }
  &:focus {
    background-color: $color;
  }
  &:disabled {
    background-color: $disable-color;
    cursor: not-allowed;
  }
}

@mixin mixin-ghostBtn-withStatus(
  $color,
  $hover-color: lighten($color, 30%),
  $disabled-color: #ababab
) {
  &:hover {
    color: $hover-color;
    border-color: $hover-color;
    svg {
      fill: $hover-color;
    }
  }
  &:active {
    color: $color;
    border-color: $color;
    svg {
      fill: $color;
    }
  }
  &:focus {
    color: $color;
    border-color: $color;
    svg {
      fill: $color;
    }
  }
  &:disabled {
    color: $disabled-color;
    border-color: $disabled-color;
    cursor: not-allowed;
    svg {
      fill: $disabled-color;
    }
  }
}
@mixin mixin-textBtn-withStatus(
  $hover-color: lighten($primary-color, 30%),
  $link-disabled-color: #aaaaad
) {
  &:hover {
    color: $hover-color;
    svg {
      fill: $hover-color;
    }
  }
  &:disabled {
    color: $link-disabled-color;
    cursor: not-allowed;
  }
}
@mixin mixin-linkBtn-withStatus(
  $hover-color: lighten($primary-color, 30%),
  $link-disabled-color: #aaaaad
) {
  &:hover {
    color: $hover-color;
    svg {
      fill: $hover-color;
    }
  }
  &:active {
    color: $hover-color;
  }
  &:focus {
    color: $hover-color;
  }
  &:disabled {
    color: $link-disabled-color;
    cursor: not-allowed;
  }
}
@mixin respond-to($breakpoint) {
  @if map-has-key(var.$breakpoints, $breakpoint) {
    $breakpoint-value: map-get(var.$breakpoints, $breakpoint);
    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn "The breakpoint `#{$breakpoint}` does not exist in your map.";
  }
}
