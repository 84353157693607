.popoverContainer {
  position: relative;
  display: inline-block;
}

.triggerElement {
  cursor: pointer;
  font-size: 0;
}
$arrow-border-width: -8px;

.popoverContent {
  position: fixed; /* 使用 fixed 而非 absolute，以便于视窗滚动时保持位置 */
  z-index: 100;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;

  display: flex;
  border-radius: 16px;
  background: #fff;
  opacity: 0;
  box-shadow: 0px 0.8px 4px 0px rgba(3, 4, 71, 0.08),
    0px 6px 12px 0px rgba(3, 4, 71, 0.1);
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  .showBorder {
    border: 2px solid #3021d9;
  }
}
