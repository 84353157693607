// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'sass:map';

// @font-face {
//   font-family: 'Inter';
//   src: url('../assets/fonts/Inter-Regular.woff2');
// }
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Outfit';
  src: url('../assets/fonts/Outfit-Regular.ttf');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  user-select: none;
  font-family: 'Poppins';

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px transparent;
    background: transparent;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #a5a9ad;
    }
  }
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
  background-color: $primary-container;
}

body,
button,
input,
select,
textarea {
  font-family: $default-font-family;
}

li {
  list-style: none;
}

img {
  display: block;
}

a {
  text-decoration: none;
}

input {
  border-style: solid;
}

button {
  border: none;
}

.link {
  color: $link;
}

@each $size, $style in $headline-text-styles {
  .headline-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}
@each $size, $style in $title-text-styles {
  .title-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}
@each $size, $style in $body-text-styles {
  .body-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}
@each $size, $style in $label-text-styles {
  .label-#{$size} {
    font-size: map.get($style, 'fs');
    font-weight: map.get($style, 'fw');
    line-height: map.get($style, 'lh');
  }
}

// Global property
.cursor-pointer {
  cursor: pointer;
}

.placeholder {
  background: $placeholder;
  cursor: wait;
  border-radius: $border-radius-3;
}

.placeholder-text {
  width: 80%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inside-border {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border: 1px solid $divider;
    left: 0;
    top: 0;
    border-radius: $border-radius-3;
    pointer-events: none;
    z-index: $z-index-menu;
  }
}

.glass-edge {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

// 修改react-quick-pinch-zoom这个库的默认样式
.kvfysmfp {
  height: 100%;
}

body {
  > canvas[id='view3D-canvas'] {
    width: 0;
    height: 0;
    display: none;
  }
}

.filled-error-button {
  background: $error-dark;
  color: $white;
  border-radius: $border-radius-3;
  position: relative;
  outline: none;
  border: 0;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-cover1;
    }
  }

  &:active {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white-cover2;
    }
  }

  &:disabled {
    color: $disabled-text;
    background: $disable-container;
    cursor: auto;
  }
}
