// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

@use 'scss/variables';

.container {
  position: absolute;
  width: 280px;
  top: 92px;
  right: 0px;
  background: variables.$overlay;
  border-radius: variables.$border-radius-12;
  backdrop-filter: blur(50px);
  padding: 24px;
  z-index: variables.$z-index-mask-panel;

  .header {
    display: flex;
    height: 44px;
    justify-content: space-between;
    align-items: center;

    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }

    .username {
      width: 160px;
      color: variables.$white;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    .logout {
      width: 20px;
      height: 20px;
      fill: variables.$white;
    }
  }

  .divider {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 8px 0;
    width: 280px;
    margin-left: -24px;
  }

  .info {
    color: variables.$white;

    > dt,
    > dd {
      height: 32px;
      padding: 6px 0;
      margin-bottom: 4px;
    }

    .label {
      color: variables.$secondary-text-white;
    }

    dt {
      font-weight: 600;
      line-height: 24px;
    }

    dd {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .next_plan {
    margin-top: 6px;
    color: #d95893;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    top: 150px;
    left: 20px;
  }
}
